export default [
  {
    key: "lead_name",
    label: "Client",
  },

  {
    key: "risk_type",
    label: "Risky",
  },

  {
    key: "mobile",
    label: "Mobile",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
  {
    key: "created_at",
    label: "created",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
  {
    key: "created_by",
    label: "created by",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
  {
    key: "type_motive",
    label: "Motive",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
  {
    key: "count_call",
    label: "Call Counter",
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "last_call",
    label: "Date Call",
    thClass: "text-center",
    tdClass: "text-center  pt-1",
  },
  {
    key: "action",
    label: "Action",
    thClass: "text-center",
  },
  {
    key: "completed_by",
    label: "Completed By",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
];
