<template>
  <div>
    <div class="border-top-primary border-3 border-table-radius px-0">
      <filter-slot
        :filter-principal="filterPrincipal"
        :filter="filter"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refCallroundsCE'].refresh()"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refCallroundsCE"
            small
            :items="myProvider"
            :fields="fieldsData"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(lead_name)="data">
              <div class="text-left">
                <div class="d-flex flex-row">
                  <p
                    class="p-0 m-0"
                    style="padding-left: 4px !important"
                    :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  >
                    {{ data.item.lead_name }}
                  </p>
                </div>
                <div>
                    <status-account
                      :account="{ ...data.item, status: data.item.status_account }"
                    ></status-account>
                  </div>
                <p class="m-0">
                  {{ data.item.account }}
                </p>
              </div>
            </template>
            <template #cell(risk_type)="data">
              <badge-risky-clients
                :idAccount="data.item.client_account_id"
                :riskLevel="data.item.risk_level"
                :riskDescription="data.item.risk_description"
                :riskType="data.item.risk_type"
              />
            </template>

            <template #cell(created_at)="data">
              <p>{{ data.item.created_at | myGlobalDay }}</p>
            </template>
            <template #cell(type_motive)="data">
              <b-badge
                pill
                :variant="`light-${typeMotive(data.item.motive_type)}`"
                class="text-capitalize w-100"
                >{{ data.item.motive_name }}</b-badge
              >
            </template>

            <template #cell(count_call)="data">
              <div class="d-flex justify-content-center">
                <b-badge
                  v-if="data.item.count_call > 0"
                  v-b-tooltip.hover.left="'Tracking Call'"
                  pill
                  variant="warning"
                  class="padding-pill cursor-pointer"
                  @click="trackingCall(data.item)"
                >
                  {{ data.item.count_call }}</b-badge
                >
                <b-badge v-else pill variant="secondary" class="padding-pill"
                  >0</b-badge
                >
              </div>
            </template>
            <template #cell(last_call)="data">
              <p>{{ data.item.last_call | myGlobalDay }}</p>
            </template>
            <template #cell(action)="data">
              <div style="padding: 3px" class="text-center">
                <b-button variant="outline" @click="validateAccess(data.item)">
                  <feather-icon
                    v-b-tooltip.hover.top="'Available'"
                    icon="PhoneCallIcon"
                    class="text-success"
                  />
                </b-button>
                <b-button variant="outline" @click="notAvailable(data.item, 2)">
                  <feather-icon
                    v-b-tooltip.hover.top="'Not available'"
                    icon="PhoneMissedIcon"
                    class="text-warning"
                  />
                </b-button>
                <b-button variant="outline" @click="notAnswer(data.item, 1)">
                  <feather-icon
                    v-b-tooltip.hover.top="'Not answer'"
                    icon="PhoneOffIcon"
                    class="text-danger"
                  />
                </b-button>
                <b-button variant="outline" @click="openSendSms(data.item)">
                  <feather-icon
                    v-b-tooltip.hover
                    title="Send Sms"
                    icon="MailIcon"
                    class="text-primary"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </div>
    <modal-reschedule
      v-if="modalRrescheduleState"
      :client-data="clientData1"
      @hideModal="modalRrescheduleState = false"
      @refresh="$refs['refCallroundsCE'].refresh()"
    />

    <modal-tracking-call-rounds
      v-if="modalTrackingState"
      :call-data="trackingData"
      @hideModal="modalTrackingState = false"
    />

    <modal-validate-access
      v-if="modalStateV"
      :client-data="clientData2"
      :motive-type="motive_type"
      @closeModal="closeModalValidate"
    />
    <ModalSendSms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.client_account_id, name: client.lead_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />
  </div>
</template>

<script>
import ModalValidateAccess from "@/views/commons/components/search-digital/modal/ModalValidateAccess.vue";
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue";
import { mapGetters, mapActions } from "vuex";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import fields from "../data/fields.callround.data";
import CallRoundService from "../../service/callround.service";
import ModalReschedule from "./ModalReschedule.vue";
import ModalTrackingCallRounds from "./ModalTrackingCallRounds.vue";
import ModalSendSms from "@/views/commons/components/clients/modals/ModalSendSms";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    ModalReschedule,
    ModalTrackingCallRounds,
    ModalValidateAccess,
    IconStatus,
    BadgeRiskyClients,
    ModalSendSms,
    StatusAccount,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 5,
      },
      filter: [
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "From",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "To",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
      ],
      trackingData: [],
      totalRows: 0,
      fields,
      startPage: null,
      endPage: null,
      toPage: null,
      modalTrackingState: false,
      clientData1: [],
      modalRrescheduleState: false,
      clientData2: [],
      modalStateV: false,
      isBusy: false,
      modalSendSms: false,
      client: {},
      motive_type: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    fieldsData() {
      if (this.$route.meta.tab == 2) {
        return this.fields.filter((item) => item.key != "action");
      }
      return this.fields.filter((item) => item.key != "completed_by");
    },
  },
  methods: {
    statusColor(status) {
      let color = "";
      switch (status) {
        case "HIGH":
          color = "danger";
          break;
        case "MEDIUM":
          color = "warning-light";
          break;
        case "LOW":
          color = "secondary";
          break;
      }
      return color;
    },
    ...mapActions({
      answerCallround: "DbAgentStore/A_ANSWER_CR",
      endCallround: "DbAgentStore/A_END_CR",
    }),
    typeMotive(type) {
      switch (type) {
        case 1:
          return "primary";
          break;
        case 2:
          return "warning";
          break;
        case 3:
          return "info";
          break;
        case 4:
          return "warning";
          break;
        case 5:
          return "success";
          break;
        case 6:
          return "info";
          break;
        case 7:
          return "info";
          break;
        default:
          break;
      }
    },
    // Modal
    trackingCall(data) {
      this.trackingData = data;
      this.modalTrackingState = true;
    },
    notAvailable(item, type) {
      this.modalRrescheduleState = true;
      this.clientData1 = item;
    },
    // Modal
    validateAccess(item) {
      if (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 14 ||
        this.currentUser.role_id == 17
      ) {
        this.addPreloader();
        this.$router.push({
          name: "ce-customer-service-dashboard",
          params: {
            idClient: item.client_account_id,
            hasAccess: true,
            typeAccess: item.motive_id,
            wayAccess: 2,
            call_round_id: item.call_round_id,
          },
        });
        this.removePreloader();
      } else {
        const data = {
          call_round_id: item.call_round_id,
          lead_name: item.lead_name,
          client_account_id: item.client_account_id,
          client_id: item.client_id,
          typeAccess: item.motive_id,
          wayAccess: 2, // call rounds
          hasAccess: true,
        };
        this.clientData2 = data;
        this.motive_type = item.motive_id;
        this.modalStateV = true;
      }
    },

    async notAnswer(item, type) {
      try {
        const confirm = await this.showConfirmSwal(
          type == 1 ? "Client did not respond?" : "Is client busy?",
          "You won't be able to revert this!"
        );
        if (confirm.value) {
          const params = {
            user_id: this.currentUser.user_id,
            client_id: item.client_account_id,
            status: type,
            call_type: type,
            call_round_id: item.call_round_id,
          };

          const { data, status } =
            await CallRoundService.setTrackingCallRoundCE(params);
          if (status == 200) {
            this.$refs.refCallroundsCE.refresh();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async myProvider() {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          orderby: 12, // DATE CALL
          order: this.$route.meta.tab == 1 ? "asc" : "desc",
          status: this.$route.meta.tab,
        };
        const { status, data } = await CallRoundService.getClientCallRoundsCE(
          params
        );
        if (status == 200) {
          this.startPage = data.from;
          this.totalRows = data.total;
          this.paginate.currentPage = data.current_page;
          this.paginate.perPage = data.per_page;
          this.endPage = data.last_page;
          this.toPage = data.to;
          return data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeModalValidate() {
      this.endCallround();
      this.modalStateV = false;
    },
    openSendSms(client) {
      this.client = client;

      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
    },
  },
};
</script>

<style scoped>
.padding-pill {
  margin-top: 5px;
  padding: 9px 10px 7px 10px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
