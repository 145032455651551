<template>
  <div>
    <b-modal
      ref="tracking-call-rounds"
      title="TRACKING"
      size="lg"
      modal-class="modal-primary "
      @hidden="hideModal"
      title-tag="h3"
      hide-footer
      :no-close-on-backdrop="true"
    >
      <b-container>
        <b-table
          slot="table"
          ref="refTrackingTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="visibleFields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"> </b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status == 'NOT ANSWER'"
              pill
              variant="danger"
              class="text-capitalize w-100"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status == 'NOT AVAILABLE'"
              pill
              variant="warning"
              class="text-capitalize w-100"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status == 'AVAILABLE'"
              pill
              variant="success"
              class="text-capitalize w-100"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(created_at)="data">
            <div>
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
import CallRoundService from "../../service/callround.service";
export default {
  components: {
    ModalHeader,
  },
  props: {
    callData: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "created_at",
          label: "Date",
          visible: true,
        },
        {
          key: "created_by",
          label: "Created By",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          visible: true,
          thClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    this.toggleModal("tracking-call-rounds");
  },

  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal");
    },

    async myProvider() {
      try {
        const params = {
          call_round_id: this.callData.call_round_id,
        };

        const response = await CallRoundService.getTrackingCallRoundCE(params);
        if (response.status == 200) {
          return response.data;
        }
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped></style>
